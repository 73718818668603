import * as React from "react";
import { useEffect } from "react";
// components
import ToolBarSection from "../components/organisms/ToolBarSection";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import HeroSection from "../components/organisms/HeroSection";
import ShwraFeatureSection from "../components/organisms/ShwraFeatureSection";
import OurServiceSection from "../components/organisms/OurServiceSection";
// new
import WhatsappButton from "../components/atoms/WhatsappButton";
import { Media } from "../components/atoms/Media";
import OurServiceSectionMobile from "../components/MobileViewComponents/Services/OurServiceSectionMobile";
import "../styles/MobileView.css";
import HowtoUseMobile from "../components/MobileViewComponents/Usage/HowtoUseMobile";
import FaqMobile from "../components/MobileViewComponents/Faq/FaqMobile";
import FooterMobile from "../components/MobileViewComponents/Footer/FooterMobile";
import Majors from "../components/organisms/Majors";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  const organization = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    url: "https://www.shwra.sa/",
    logo: {
      "@type": "ImageObject",
      url: "https://www.shwra.sa/",
    },
    name: "شورى",
    email: "info@shwra.sa",
    description:
      "شورى هي منصة رقمية تعنى بتقديم الاستشارات والخدمات القانونية عن بعد في جميع مناطق المملكة العربية السعودية من خلال ربط العملاء طالبي الاستشارات والخدمات القانونية، مع نخبة من المحامين المرخصين من وزارة العدل السعودية بطريقة تفاعلية، سهلة وآمنة ومهنية واستناداً إلى القوانين مع الحفاظ على سرية وخصوصية بيانات العميل.اعرف المزيد >",
    telephone: "920033635",
  };

  const website = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "شورى",
    url: "https://www.shwra.sa/",
  };

  const LegalServices = {
    "@context": "https://schema.org",
    "@type": "LegalService",
    name: "شورى",
    address: {
      "@type": "PostalAddress",
      streetAddress: "",
      addressLocality: "الرياض",
      addressRegion: "السعودية",
      postalCode: "",
    },
    image:
      "https://www.shwra.sa/static/fc6fc04a45ffcb670b257529cbc148e7/bb5a0/logo%40yellow.webp",
    email: " info@shwra.sa",
    telePhone: "920033635",
    url: "https://www.shwra.sa/",
    paymentAccepted: ["credit card", "paypal"],
    openingHours: "Mo,Tu,We,Th,Su 0-0",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Sunday"],
        opens: "0",
        closes: "0",
      },
    ],
    priceRange: "$$",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="احصل على استشارات قانونية موثوقة وفورية أونلاين مع مكتب محاماة شورى. فريق من المحامين المتخصصين جاهزون لدعمك في جميع القضايا القانونية."
        />
        <meta
          name="keywords"
          content="مكتب محاماة, شركة محاماة, مكتب استشارات قانونية, شركة محاماة واستشارات قانونية, منصة استشارات قانونية, استشارات قانونية اون لاين"
        />
        <title>
          شورى | استشارات وخدمات قانونية | محامين مرخصين من وزارة العدل
        </title>
        <link
          rel="canonical"
          href={
            typeof window !== "undefined"
              ? `${window.location.origin}${window.location.pathname}`
              : ""
          }
        />
        <script type="application/ld+json">
          {JSON.stringify(organization)}
        </script>

        <script type="application/ld+json">{JSON.stringify(website)}</script>

        <script type="application/ld+json">
          {JSON.stringify(LegalServices)}
        </script>
      </Helmet>
      <div className="main-container" dir="rtl">
        <ToolBarSection business={false} />
        <Header />
        <HeroSection />
        <div>
          <Media at="sm">
            <OurServiceSectionMobile />
          </Media>
          <Media greaterThan="sm">
            <OurServiceSection />
          </Media>
          <ShwraFeatureSection />
          <Majors />
          <HowtoUseMobile />
          <FaqMobile />
          <Media at="sm">
            <FooterMobile />
          </Media>
          <Media greaterThan="sm">
            <Footer />
          </Media>
          <WhatsappButton />
        </div>
      </div>
    </>
  );
};

export default IndexPage;
